import React from 'react';

const Terms = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Service</h1>
      <p>Last updated: October 25, 2024</p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>By accessing this website, you agree to be bound by these Terms of Service and all applicable laws and regulations.</p>
      
      <h2>2. Use License</h2>
      <p>Permission is granted to temporarily view the materials on this website for personal, non-commercial use only. This is the grant of a license, not a transfer of title.</p>
      
      <h2>3. Disclaimer</h2>
      <p>The materials on this website are provided on an 'as is' basis. I make no warranties, expressed or implied, and hereby disclaim and negate all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
      
      <h2>4. Limitations</h2>
      <p>In no event shall the company (Aberrest Partners) be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on this website.</p>
      
      <h2>5. Revisions and Errata</h2>
      <p>The materials appearing on this website could include technical, typographical, or photographic errors. I do not warrant that any of the materials on this website are accurate, complete or current.</p>
      
      <h2>6. Links</h2>
      <p>I have not reviewed all of the sites linked to this website and am not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by me of the site. Use of any such linked website is at the user's own risk.</p>
      
      <h2>7. Modifications</h2>
      <p>I may revise these terms of service for this website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms of Service.</p>
      
      <h2>8. Governing Law</h2>
      <p>These terms and conditions are governed by and construed in accordance with the laws of the United States and you irrevocably submit to the exclusive jurisdiction of the courts in the United States.</p>
    </div>
  );
};

export default Terms;

