import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import { Tweet } from 'react-tweet'
import Contact from './Contact';
import 'highlight.js/styles/github-dark.css';
import '../../style/BlogPost.css';

const BlogPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [content, setContent] = useState('');

  useEffect(() => {
    // First fetch the post metadata
    fetch('/content/blog.json')
      .then(res => res.json())
      .then(data => {
        const foundPost = data.posts.find(post => post.slug === slug);
        if (foundPost) {
          setPost(foundPost);
          // Then fetch the markdown content using the slug
          return fetch(`/content/blog/${foundPost.slug}.md`);
        } else {
          throw new Error('Post not found');
        }
      })
      .then(res => res.text())
      .then(markdownContent => {
        setContent(markdownContent);
      })
      .catch(err => {
        console.error('Error loading blog post:', err);
        navigate('/blog');
      });
  }, [slug, navigate]);

  if (!post || !content) return <div>Loading...</div>;

  return (
    <div className="main">
      <header className="header">
        <div className="title-container">
          <h1 className="title">Aberrest</h1>
          <h2 className="subtitle">Applied AI Research & Development</h2>
        </div>
        <div className="logo-animated"></div>
      </header>

      <div className="blog-post">
        <div className="back-button" onClick={() => navigate('/blog')}>← Blog</div>
        <Helmet>
          <title>{post.title} | Aberrest Blog</title>
          <meta name="description" content={post.preview} />
          {/* ... other meta tags ... */}
        </Helmet>

        <article className="blog-post-content">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeHighlight]}
            components={{
              // Custom components for markdown elements
              h1: ({node, ...props}) => <h1 className="blog-post-h1" {...props} />,
              h2: ({node, ...props}) => <h2 className="blog-post-h2" {...props} />,
              h3: ({node, ...props}) => <h3 className="blog-post-h3" {...props} />,
              code: ({node, inline, ...props}) => 
                inline ? <code className="blog-post-inline-code" {...props} /> 
                      : <code className="blog-post-code-block" {...props} />,
              blockquote: ({node, ...props}) => <blockquote className="blog-post-quote" {...props} />,
              img: ({node, ...props}) => <img className="blog-post-image" {...props} alt={props.alt || ''} />,
              Tweet: ({node, ...props}) => <Tweet {...props} />,
            }}
          >
            {content}
          </ReactMarkdown>
        </article>
        <br></br>
        <div className="back-button" onClick={() => navigate('/blog')}>← Blog</div>
        <Contact />  
      </div>
    </div>
  );
};

export default BlogPost;