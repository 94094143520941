import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Contact from './component/Contact';
import '../style/Projects.css';

const ProjectCard = ({ project }) => (
  <div className="project-card">
    <h3 className="project-title">{project.title}</h3>
    <div className="project-meta">
      <span className="project-category">{project.category}</span>
    </div>
    <p className="project-description">{project.description}</p>
    <div className="project-tech">
      {project.technologies.map((tech, index) => (
        <span key={index} className="tech-tag">{tech}</span>
      ))}
    </div>
    <ul className="project-highlights">
      {project.highlights.map((highlight, index) => (
        <li key={index}>{highlight}</li>
      ))}
    </ul>
    {project.contact ? (
      <a href={project.contact} className="project-contact-link">
        {project.availability}
      </a>
    ) : (
      <div className="project-availability">{project.availability}</div>
    )}
  </div>
);

const Projects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch('/content/projects.json')
      .then(res => res.json())
      .then(data => setProjects(data.projects))
      .catch(err => console.error('Error loading projects:', err));
  }, []);

  return (
    <div className="main">
        <header className="header">
            <div className="title-container">
                <h1 className="title">Aberrest</h1>
                <h2 className="subtitle">Applied AI Strategy & Development</h2>
            </div>
            <div className="logo-animated"></div>
        </header>

        <div className="projects">
            <header className="projects-header">
                <h1 className="projects-header-title">Project Repository</h1>
                <div className="back-button" onClick={() => navigate('/')}>← Home</div>
            </header>
            
            <Contact />
            
            <div className="projects-grid">
                <h3>Our development portfolio spans industries and use cases. Current and past projects:</h3>
                {projects.map(project => (
                <ProjectCard key={project.title} project={project} />
                ))}
            </div>
        </div>
    </div>
  );
};

export default Projects;