import React from 'react';
import Contact from './Contact';

const About = () => {
  return (
    <div className="about-container">
      <h1>About Aberrest</h1>
      <h3>
        Aberrest Partners, founded in 2019, bridges the gap between machine learning and real-world applications.
      </h3>  
      <p>
        Building on our foundation in enterprise research solutions, we've evolved to specialize in reasoning systems, intelligence automation, and collaborative agents that transform how organizations work. Our solutions serve diverse industries - including financial services, pharmaceuticals, media & entertainment, and IT - delivering tangible business value through applied AI and machine learning.
      </p>
      <h2>Our Approach</h2>  
      <p>
        We focus on four major principles at every layer for our partners:
        <ul>
          <li>Security</li>
          <li>Usability</li>
          <li>Scalability</li>
          <li>Productivity</li>
        </ul>
        This comprehensive approach ensures seamless, production-ready solutions that drive real business outcomes and emphasizes privacy and security of your company's data, while empowering users.
      </p>
      <br />
        <Contact />
    </div>
  );
};

export default About;

