import React from 'react';

const Privacy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <p>Last updated: October 25, 2024</p>
      
      <h2>1. Information Collection</h2>
      <p>This website is primarily used for publishig and showcasing the work of Aberrest Partners and does not collect personal information from visitors.</p>
      
      <h2>2. Use of Cookies</h2>
      <p>This site may use cookies to enhance your browsing experience. These cookies do not contain personal information.</p>
      
      <h2>3. Third-Party Links</h2>
      <p>This website may contain links to external sites. I am not responsible for the privacy practices of these external sites.</p>
      
      <h2>4. Changes to This Policy</h2>
      <p>I reserve the right to update this privacy policy at any time. Changes will be posted on this page.</p>
      
      <h2>5. Contact</h2>
      <p>If you have any questions about this privacy policy, please contact me at admin@aberrest.com.</p>
    </div>
  );
};

export default Privacy;

