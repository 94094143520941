import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Contact from './component/Contact';
import '../style/Blog.css';

const BlogCard = ({ post }) => {
    const navigate = useNavigate();
  
    return (
        <div 
            className="blog-card" 
            onClick={() => navigate(`/blog/${post.slug}`)}
            style={{ cursor: 'pointer' }}
        >
            <div className="blog-card-content">
                <h3 className="blog-title">{post.title}</h3>
                <div className="blog-meta">
                    <span className="blog-date">{post.date}</span>
                    <span className="blog-readtime">{post.readtime}</span>
                </div>
                <div className="blog-tags">
                    {post.tags.map((tag, index) => (
                        <span key={index} className="blog-tag">{tag}</span>
                    ))}
                </div>
                <p className="blog-preview">{post.preview}</p>
            </div>
        </div>
    );
};

const Blog = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('/content/blog.json')
      .then(res => res.json())
      .then(data => setPosts(data.posts))
      .catch(err => console.error('Error loading blog posts:', err));
  }, []);

  return (
    <div className="main">
      <header className="header">
        <div className="title-container">
          <h1 className="title">Aberrest</h1>
          <h2 className="subtitle">Applied AI Strategy & Development</h2>
        </div>
        <div className="logo-animated"></div>
      </header>

      <div className="blog">
        <div className="blog-header">
            <h1 className="blog-page-title">BLOG: Applied Intelligence</h1>
            <div className="back-button" onClick={() => navigate('/')}>← Home</div>
        </div>
        <div className="blog-grid">
            {posts.map(post => (
            <BlogCard key={post.slug} post={post} />
            ))}
        </div>

        <Contact />

      </div>
    </div>
  );
};

export default Blog;